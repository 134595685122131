@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");

* {
  margin: 0;
  padding: 0;
}

html,
body {
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
  scroll-behavior: smooth;
}
