@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  position: relative;
  scroll-behavior: smooth;
}
.scrollToTop {
  width: 4rem;
  height: 4rem;
  background-color: transparent;
  position: fixed;
  /* bottom: -100rem; */
  right: 2rem;
  border: 0.2rem solid #f3f3f3;
  border-radius: 0.6rem;
  z-index: 10000 !important;
  transition: 2s;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.3),
    0 -0.4rem 1rem rgba(0, 0, 0, 0.3);
}
.scrollToTop i {
  font-size: 3rem;
  color: #fb4a5b;
}
.show-scroll {
  /* top: initial; */
  bottom: 4rem;
  visibility: visible;
}
.hide-scroll {
  /* bottom: initial; */
  bottom: 100rem;
  visibility: hidden;
}
.v-hidden {
  visibility: hidden;
}
.construction {
  position: fixed;
  z-index: 1000000;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.construction div {
  width: 30rem;
  max-width: 90vw;
  height: 4rem;
}
.construction div img {
  width: 100%;
}
